import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useHistory, useLocation, matchPath } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Map, TileLayer, Marker, Tooltip } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { reverse } from "named-urls";
import moment from "moment/moment";
import queryString from "query-string";
import api from "services/api";
import wordpressApi from "services/wordpress";
import eduApi from "services/eduApi";
import badApi from "services/badApi";
import url from "router/urls";
import storage from "scripts/storage";

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Loader from "@/components/Loader/Loader";
import Error from "@/components/error/Error";
import SearchMain from "@/components/Search/SearchMain";

import { Button } from "@mui/material";

import styles from "./Details.module.sass";
import AddToCollectionModal from "@/components/modals/AddToCollectionModal";
import ConfirmModal from "@/components/modals/ConfirmModal";
import useToken from "@/components/hooks/useToken";
import { format } from "scripts/utils";
import imageNotFound from "@/assets/icons/svg/image-not-found.svg";

import imgMarkerObject from "assets/images/markerObject.svg";
import axios from "axios";

const markerObject = new L.Icon({
	iconUrl: imgMarkerObject,
	iconRetinaUrl: imgMarkerObject,
	iconAnchor: null,
	popupAnchor: [0, -20],
	shadowUrl: null,
	shadowSize: null,
	shadowAnchor: null,
	iconSize: new L.Point(45, 45),
	className: "map__marker--object",
});

/**
 * Map: find route
 * @function findRoute
 */

function findRoute(from, ...routes) {
	if (from) {
		if (routes) {
			const url = from.split("?")[0];
			for (let route of routes) {
				let match = matchPath(url, {
					path: route,
				});
				if (match) {
					return match.path;
				}
			}
		}
	}
	return null;
}

/**
 * get from param
 * @function getFromParam
 */

function getFromParam() {
	const extractParameter = new URL(window.location);
	const from = extractParameter.searchParams.get("from");
	return from;
}

/**
 * strip url
 * @function stripURL
 */

function stripURL(refferer) {
	if (refferer) {
		try {
			const url = new URL(refferer);
			return url.pathname + url.search;
		} catch (ex) {
			// Ignore
		}
	}
	return null;
}

/**
 * check is valid external url
 * @function isValidExternalUrl
 */

const isValidExternalUrl = link => {
	try {
		const url = new URL(link);
		return url.hostname !== "";
	} catch (e) {
		return false;
	}
};

/**
 * remove duplicates
 * @function removeDuplicates
 */

function removeDuplicates(arr) {
	return [...new Set(arr)];
}

/**
 * get embedded date
 * @function getEmbeddedDate
 */

function getEmbeddedDate(item, language) {
	if (item?._embedded?.self && item?._embedded?.self[0] && item?._embedded?.self[0].date) {
		return moment(item?._embedded?.self[0].date).format(moment.localeData(language).longDateFormat("L"));
	}
	return "";
}

/**
 * get embedded title
 * @function getEmbeddedTitle
 */

function getEmbeddedTitle(item, language) {
	if (item?._embedded?.self && item?._embedded?.self[0] && item?._embedded?.self[0].acf[`${language}_title`]) {
		return item?._embedded?.self[0].acf[`${language}_title`];
	}
	if (item?._embedded?.self && item?._embedded?.self[0] && item?._embedded?.self[0].title?.rendered) {
		return item?._embedded?.self[0].title?.rendered;
	}
}

/**
 * get embedded description
 * @function getEmbeddedDescription
 */

function getEmbeddedDescription(item, language) {
	if (item?._embedded?.self && item?._embedded?.self[0] && item?._embedded?.self[0].acf[`${language}_excerpt`]) {
		return item?._embedded?.self[0].acf[`${language}_excerpt`];
	}
	if (item?._embedded?.self && item?._embedded?.self[0] && item?._embedded?.self[0].excerpt?.rendered) {
		return item?._embedded?.self[0].excerpt?.rendered;
	}
	return "";
}

/**
 * Details back button
 * @memberof Components
 * @function DetailsBackButton
 */

const DetailsBackButton = () => {
	const { t } = useTranslation();
	let text = t("details:nav.backToSearchResults");
	const location = useLocation();
	const returnUrl =
		location.state?.returnURL ||
		location.state?.from ||
		stripURL(document.referrer) ||
		getFromParam() ||
		url.allResults;
	const route = findRoute(
		returnUrl,
		url.last,
		url.results,
		url.recomended,
		url.collections.item,
		url.collections.my,
		url.collections.sharedItem,
		url.topics.item,
		url.topics.my,
		url.home
	);

	switch (route) {
		case url.last:
			text = t("details:nav.backToLastAdded");
			break;
		case url.recomended:
			text = t("details:nav.backToRecommended");
			break;
		case url.home:
			text = t("details:nav.backToHomePage");
			break;
		case url.collections.my:
			text = t("details:nav.backToCollection");
			break;
		case url.collections.sharedItem:
			text = t("details:nav.backToCollection");
			break;
		case url.collections.item:
			text = t("details:nav.backToCollection");
			break;
		case url.topics.item:
			text = t("details:nav.backToTopic");
			break;
	}

	return (
		<Button
			variant="link"
			size="small"
			component={Link}
			to={returnUrl}
			sx={{
				"@media (max-width: 991.98px)": {
					px: "0 !important",
				},
			}}
		>
			<i className="icon-arrow-left" />
			{text}
		</Button>
	);
};

/**
 * Details navigation buttons
 * @memberof Components
 * @function DetailsNavButtons
 */

const DetailsNavButtons = () => {
	const { t } = useTranslation();
	const { id } = useParams();
	let history = useHistory();
	const location = useLocation();
	const from = location.state?.from || getFromParam() || stripURL(document.referrer) || url.allResults;
	const fromResults = findRoute(from, url.results) === url.results;
	const fromTopic = findRoute(from, url.topics.item) === url.topics.item;
	const site = from.split("?")[0];
	const title = location.state?.title;
	const [nextFrom, setNextFrom] = useState(from);
	const [prevFrom, setPrevFrom] = useState(from);

	const [objectsIds, setObjectsIds] = useState([]);
	const [objectPrevId, setObjectPrevId] = useState(null);
	const [objectNextId, setObjectNextId] = useState(null);

	useEffect(() => {
		const objectIds = storage.getObject(from, []);
		setObjectsIds(removeDuplicates(objectIds));
	}, [from]);

	useEffect(() => {
		setObjectNextId(null);
		setObjectPrevId(null);
		setNextFrom(from);
		setPrevFrom(from);

		let position = objectsIds.findIndex(elem => elem === id);
		if (position > -1) {
			const next = position + 1;
			const prev = position - 1;

			if (objectsIds[next]) setObjectNextId(objectsIds[next]);
			if (objectsIds[prev]) setObjectPrevId(objectsIds[prev]);

			if (fromResults || fromTopic) {
				const searchParams = queryString.parse(from.split("?")[1]);
				const currentPage = parseInt(searchParams.page || 1);
				const pageSize = parseInt(searchParams.pageSize || storage.getInt("pageSize", 25));

				if (position === 0 && currentPage > 1) {
					searchParams.page = (currentPage - 1).toString();
					searchParams.pageSize = pageSize.toString();
					const newFrom = site + "?" + queryString.stringify(searchParams);
					const cachedPage = storage.getObject(newFrom, []);
					if (!cachedPage || !cachedPage.length) {
						searchParams.page = (currentPage - 2).toString();
						const realUrl = url.results + "?" + queryString.stringify(searchParams);
						api
							.get(realUrl)
							.then(({ data }) => {
								const documents = data.documents;
								if (documents && documents.length) {
									const objects = documents.map(doc => doc.id);
									storage.saveObject(newFrom, objects);
									setObjectPrevId(objects[objects.length - 1]);
									setPrevFrom(newFrom);
								}
							})
							.catch(() => {});
					} else {
						setObjectPrevId(cachedPage[cachedPage.length - 1]);
						setPrevFrom(newFrom);
					}
				}

				if (position === objectsIds.length - 1) {
					searchParams.page = (currentPage + 1).toString();
					searchParams.pageSize = pageSize.toString();
					const newFrom = site + "?" + queryString.stringify(searchParams);
					const cachedPage = storage.getObject(newFrom, []);
					if (!cachedPage || !cachedPage.length) {
						searchParams.page = currentPage.toString();
						const realUrl = url.results + "?" + queryString.stringify(searchParams);
						api
							.get(realUrl)
							.then(({ data }) => {
								const documents = data.documents;
								if (documents && documents.length) {
									const objects = documents.map(doc => doc.id);
									storage.saveObject(newFrom, objects);
									setObjectNextId(objects[0]);
									setNextFrom(newFrom);
								}
							})
							.catch(() => {});
					} else {
						setObjectNextId(cachedPage[0]);
						setNextFrom(newFrom);
					}
				}
			}
		}
	}, [objectsIds, id]);

	const handleNext = from => {
		history.push(reverse(url.details, { id: objectNextId }), { from: from, title: title });
	};

	const handlePrev = from => {
		history.push(reverse(url.details, { id: objectPrevId }), { from: from, title: title });
	};

	return (
		<>
			<Button
				variant="primary"
				size="small"
				onClick={() => handlePrev(prevFrom)}
				disabled={!objectPrevId}
				sx={{
					"@media (max-width: 991.98px)": {
						px: "15px",
						py: "10px",
					},
				}}
			>
				{t("details:nav.prevResult")}
			</Button>
			<Button
				variant="primary"
				size="small"
				onClick={() => handleNext(nextFrom)}
				disabled={!objectNextId}
				sx={{
					"@media (max-width: 991.98px)": {
						px: "15px",
						py: "10px",
					},
				}}
			>
				{t("details:nav.nextResult")}
			</Button>
		</>
	);
};

/**
 * Details page
 * @memberof Containers
 * @function Details
 */

const Details = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const { id } = useParams();
	const image_url = process.env.REACT_APP_IMAGES + `/thumbnails/${id}_original.jpg`;
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const [notFound, setNotFound] = useState(false);
	const [record, setRecord] = useState({});
	const [modalIsOpen, setIsOpen] = useState(false);
	const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false);
	const [confirmMessage, setConfirmMessage] = useState("");
	const [confirmHeader, setConfirmHeader] = useState("");
	const [eduObject, setEduObject] = useState(null);
	const [wolObject, setWolObject] = useState(null);
	const [badObject, setBadObject] = useState(null);
	const [confirmAction, setConfirmAction] = useState(function () {
		return () => console.info("Initial function");
	});
	const [recordTitle, setRecordTitle] = useState(t("details:emptyTitle"));
	const { isLoggedIn, userId, bearer } = useToken(false);
	const [myCollectionsWithObject, setMyCollectionsWithObject] = useState([]);
	const [toggleRefresh, setToggleRefresh] = useState(false);
	const [predefinedCollectionsWithObject, setPredefinedCollectionsWithObject] = useState([]);

	const url_WOL = process.env.REACT_APP_MODULES_WOL;
	const url_EDU = process.env.REACT_APP_MODULES_EDU;
	const url_BAD = process.env.REACT_APP_MODULES_BAD;
	const api_WOL = process.env.REACT_APP_MODULES_WOL_BACKEND;

	const recordDigital = "digital object";

	const from = location.state?.from || getFromParam() || stripURL(document.referrer) || url.allResults;
	const route = findRoute(
		from,
		url.last,
		url.results,
		url.recomended,
		url.collections.item,
		url.collections.my,
		url.collections.sharedItem,
		url.topics.item,
		url.topics.my,
		url.home
	);

	const getObjectDetails = () => {
		api
			.get(`/records/${id}`)
			.then(({ data }) => {
				setLoading(false);
				//DEBUG HACK for geolocation
				if (
					data.record?.original?.dcPublisher &&
					Array.isArray(data.record?.original?.dcPublisher) &&
					data.record?.original?.dcPublisher.some(x => x === "PCSS")
				) {
					if (!data.record.geolocation) {
						data.record.geolocation = [52.40723263638076, 16.952588937206166];
					}
				}
				setRecord({...data.record?.original, ...data.record});
				if (data.record?.original?.dcTitle) setRecordTitle(data.record?.original?.dcTitle.toString());
				else setRecordTitle(t("details:emptyTitle"));
			})
			.catch(() => {
				setLoading(false);
				setNotFound(true);
			});
	};

	useEffect(() => {
		getObjectDetails();
	}, [id]);

	const getUserCollectionsWithObject = () => {
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
			},
		};
		api
			.get(`/users/${userId}/static-collections?itemValue=${id}`, config)
			.then(({ data }) => {
				setMyCollectionsWithObject(data.collections);
			})
			.catch(() => {
				setMyCollectionsWithObject([]);
			});
	};

	useEffect(() => {
		if (bearer) {
			getUserCollectionsWithObject();
		} else {
			setMyCollectionsWithObject([]);
		}
	}, [bearer, userId, id, toggleRefresh]);

	const openAddToCollectionModal = () => {
		setIsOpen(true);
	};

	const getPredefinedCollectionsWithObject = () => {
		wordpressApi
			.get(`wp/v2/search?search=${id}&subtype=collections&_embed`)
			.then(({ data }) => {
				setPredefinedCollectionsWithObject(data);
				if (data?.length) {
					data.map(item => {
						const cachedCollections = storage.getObject("collections", {});
						item.jpg = cachedCollections[item.id];
						if (item.jpg) setPredefinedCollectionsWithObject([...data]);
						if (!item.jpg) {
							if (
								item._embedded?.self &&
								item._embedded?.self[0]?._links["wp:featuredmedia"] &&
								item._embedded?.self[0]?._links["wp:featuredmedia"][0]?.href
							) {
								wordpressApi
									.get(item._embedded.self[0]._links["wp:featuredmedia"][0].href)
									.then(response => {
										item.jpg = response.data?.source_url;
										if (item.jpg) {
											setPredefinedCollectionsWithObject([...data]);
											const cachedCollections = storage.getObject("collections", {});
											cachedCollections[item.id] = item.jpg;
											storage.saveObject("collections", cachedCollections);
										}
									})
									.catch(() => {});
							}
						}
					});
				}
			})
			.catch(() => {
				setPredefinedCollectionsWithObject([]);
			});
	};

	useEffect(() => {
		getPredefinedCollectionsWithObject();
	}, [id]);

	const getEduCollections = () => {
		eduApi.get(`?id=${id}`).then(({ data }) => {
			if (data.aggregator?.documents) {
				setEduObject(data.aggregator.documents);
			}
		});
	};

	useEffect(() => {
		record?.id && record?.techType === recordDigital && getEduCollections();
	}, [id, record]);

	const getBAD = () => {
		badApi.get(`projects?daceId=${id}`).then(({ data }) => {
			setBadObject(data.projects);
		});
	};

	useEffect(() => {
		record?.id && record?.techType === recordDigital && getBAD();
	}, [id, record]);

	const getWOL = async () => {
		if (record?.techVolumeLink?.length > 0) {
			const wolPromises = await record.techVolumeId.map(async id =>
				axios
					.create({
						baseURL: `${api_WOL}/volumes/${id}`,
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						crossdomain: true,
					})
					.get()
					.then(({ data }) => data)
			);

			const wolData = await Promise.allSettled(wolPromises)
				.then(values => values.map(res => res.status === "fulfilled" && res.value))
				.then(data => data);

			const wolObjFiltered = wolData.filter(e => e);

			setWolObject(wolObjFiltered);
		}
	};

	useEffect(() => {
		record?.id && record?.techType === recordDigital && getWOL();
	}, [id, record]);

	const closeAddToCollectionModal = () => {
		setIsOpen(false);
		setToggleRefresh(!toggleRefresh);
	};

	const convertDate = date => {
		return date && moment.isMoment(moment(date)) ? moment(date).format("DD.MM.YYYY") : "";
	};

	if (loading) return <Loader />;

	if (notFound) return <Error />;

	// if (error) return <Error />;

	// if (Object.keys(record).length === 0 && record.constructor === Object) return <Loader />;

	let text = location.state?.title || t("breadcrumbs.allObjects");

	let parentName = null;
	let parentUrl = null;

	switch (route) {
		case url.last:
			text = t("home:publications.title.lastAdded");
			break;
		case url.recomended:
			text = t("home:publications.title.recommend");
			break;
		case url.collections.my:
			parentName = t("default:breadcrumbs.collections");
			parentUrl = url.collections.my;
			break;
		case url.results:
			text = t("results:breadcrumbs.results");
			break;
		case url.collections.sharedItem:
			break;
		case url.collections.item:
			parentName = t("default:breadcrumbs.collections");
			parentUrl = url.collections.list;
			break;
		case url.topics.item:
			parentName = t("default:breadcrumbs.topics");
			parentUrl = url.topics.all;
			break;
	}

	if (route === url.results) {
		const searchParams = new URLSearchParams(from.split("?")[1]);
		searchParams.delete("page");
		searchParams.delete("pageSize");
		let existingParams = {};
		for (const [key, value] of searchParams.entries()) {
			if (value || value === 0) existingParams[key] = value;
		}

		const { q, sortBy, sortOrder, ...rest } = existingParams;
		if (Object.keys(rest).length === 0) {
			if (q == "*") {
				if ((sortBy == null || sortBy == "score") && (sortOrder == null || sortOrder == "desc")) {
					text = t("default:breadcrumbs.allObjects");
				} else if (sortBy == "time" && sortOrder == "desc") {
					text = t("home:publications.title.lastAdded");
				}
			}
		}
	}

	const deleteItemFromCollection = (collectionId, itemId) => {
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
			},
		};
		api
			.delete(`/users/${userId}/static-collections/${collectionId}/items/?itemValue=${itemId}`, config)
			.then(() => {
				setToggleRefresh(!toggleRefresh);
			})
			.catch(() => {});
	};

	const openConfirmModal = (collectionName, collectionId) => {
		setConfirmHeader(t("details:attention"));
		setConfirmMessage(format(t("details:collections.removeObjectQuestion"), collectionName));
		setConfirmAction(function () {
			return () => {
				deleteItemFromCollection(collectionId, id);
			};
		});
		setModalConfirmIsOpen(true);
	};

	const closeConfirmModal = () => {
		setModalConfirmIsOpen(false);
	};
	let path = [];
	if (parentName) {
		path.push({
			name: parentName,
			url: parentUrl,
		});
	}
	path.push({
		name: text,
		url: from,
	});
	path.push({
		name: recordTitle,
	});

	return (
		<div className={styles["details"]}>
			<SearchMain className={styles["details__search"]} />
			<ConfirmModal
				isOpen={modalConfirmIsOpen}
				onClose={closeConfirmModal}
				message={confirmMessage}
				header={confirmHeader}
				action={confirmAction}
			/>
			{isLoggedIn && (
				<AddToCollectionModal isOpen={modalIsOpen} id={id} uid={userId} onClose={closeAddToCollectionModal} />
			)}
			<div className={`${styles["details__container"]} container`}>
				<div className={styles["details__breadcrumbs"]}>
					<Breadcrumbs path={path} />
				</div>

				<div className={styles["details__upperhead"]}>
					<div className={styles["details__back"]}>
						<DetailsBackButton />
					</div>
					<div className={styles["details__nav"]}>
						<DetailsNavButtons />
					</div>
				</div>

				<div className={styles["details__head"]}>
					<h1 className={styles["details__text--title"]}>{recordTitle}</h1>
				</div>

				<div className={styles["details__content"]}>
					<div className={styles["details__info"]}>
						<div>
							<h2 className={styles["details__text--heading"]}>{t("details:description")}</h2>
							<div className={styles["details__blocks"]}>
								<div className={`${styles["details__block"]} ${styles["details__aside"]}`}>
									<div className={styles["details__photo"]}>
										<img
											src={image_url}
											className={styles["details__photo--img"]}
											alt="img"
											onError={({ currentTarget }) => {
												currentTarget.onerror = null;
												currentTarget.src = imageNotFound;
												currentTarget.classList.add(styles["details__photo--img--not-found"]);
											}}
										/>
									</div>
									{isLoggedIn && (
										<>
											<div className={styles["details__addItemToCollection"]}>
												<Button variant="primary" size="small" onClick={openAddToCollectionModal}>
													{t("details:addItemToCollection")}
												</Button>
											</div>
											<div className={styles["details__collections"]}>
												{myCollectionsWithObject &&
													Array.isArray(myCollectionsWithObject) &&
													myCollectionsWithObject.length > 0 && (
														<h4 className={styles["details__text--collections--header"]}>
															{t("details:itemInMyCollections")}:
														</h4>
													)}
												{myCollectionsWithObject &&
													Array.isArray(myCollectionsWithObject) &&
													myCollectionsWithObject.length > 0 &&
													myCollectionsWithObject.map(item => {
														return (
															<>
																<div key={item.id} className={styles["details__text--collections"]}>
																	<Link
																		className={styles["details__text--collections--link"]}
																		to={reverse(url.collections.myItem, { id: item.id })}
																	>
																		{item.name}
																	</Link>
																	<Button
																		className={styles["details__text--collections--delete"]}
																		variant="link"
																		size="small"
																		onClick={() => {
																			openConfirmModal(item.name, item.id);
																		}}
																	>
																		{t("details:collections.remove")}
																	</Button>
																</div>
															</>
														);
													})}
											</div>
										</>
									)}
									{/* <div className={styles["details__addItemAsTopic"]}>
                                        <Button 
                                            variant="link"
                                            onClick={() => { console.log('no function') }}
                                        >
                                            + { t('details:addItemAsTopic') }
                                        </Button>
                                    </div> */}
								</div>
								<div className={styles["details__block"]}>
									{(record.techSourceId || record.techUrl) && (
										<div className={styles["details__institution"]}>
											<div className={styles["details__institution--left"]}>
												{record.techSourceName && (
													<div className={styles["details__item"]}>
														<span className={styles["details__text--item"]}>
															{t("details:institutionObject")}: <strong>{record.techSourceName}</strong>
														</span>
													</div>
												)}
											</div>
											<div className={styles["details__institution--right"]}>
												{record.techUrl && (
													<Button variant="primary" size="small" href={record.techUrl} target="_blank">
														{t("details:openOnInstitutionPage")}
													</Button>
												)}
											</div>
										</div>
									)}

									<div className={styles["details__box"]}>
										<div className={styles["details__list"]}>
											{Object.keys(record).map(item => {
												const excludeLink = [
													"dcTitle",
													"dcDescription",
													"dcIdentificator",
													// "dcLanguage",
													"bsaDigitisation",
												];

												const removeItems = ["dcDate", "dcDateUTC"];

												const itemListCondition =
													(item.startsWith("dc") || item.startsWith("bsa")) &&
													!removeItems.includes(item) &&
													!(item.endsWith("UTC") || item.endsWith("RPT"));
												const data = record[item];

												const metadataStaticValues = [
													"techCanIUseIt",
													"bsaSimpleType",
													"techObjectAccessibility",
													"techType",
												];

												if (itemListCondition)
													return (
														<div className={styles["details__item"]} key={item}>
															<span className={styles["details__text--item"]}>
																{excludeLink.includes(item) ? (
																	<>
																		{t(`metadata:fields.${item}`)}:{" "}
																		<strong>{Array.isArray(data) ? data.join("; ") : data}</strong>
																	</>
																) : (
																	<>
																		{t(`metadata:fields.${item}`)}:<span> </span>
																		{!Array.isArray(data) &&
																			(isValidExternalUrl(data) ? (
																				<>
																					<strong>
																						<a href={data} target="_blank" rel="noreferrer">
																							{metadataStaticValues.includes(item)
																								? t(`metadata:${item}.${data}`)
																								: data}
																						</a>
																					</strong>
																				</>
																			) : (
																				<>
																					<strong>
																						<Link to={`${url.results}?q=${data}&scope=${item}`}>
																							{metadataStaticValues.includes(item)
																								? t(`metadata:${item}.${data}`)
																								: data}
																						</Link>
																					</strong>
																				</>
																			))}
																		{Array.isArray(data) &&
																			data.map((part, i) => {
																				if (isValidExternalUrl(part))
																					return (
																						<>
																							{data.length > 1 && <br />}
																							<strong>
																								<a href={part} target="_blank" rel="noreferrer">
																									{part}
																								</a>
																							</strong>
																						</>
																					);
																				else
																					return (
																						<>
																							<strong>
																								<Link to={`${url.results}?q=${item}%3A%28${part}%29&scope=`}>
																									{part}
																								</Link>
																							</strong>

																							{ data.length !== i + 1 && <span>; </span>}
																						</>
																					);
																			})}
																	</>
																)}
															</span>
														</div>
													);
											})}
										</div>
									</div>
								</div>
							</div>
						</div>

						{predefinedCollectionsWithObject.length > 0 && (
							<div>
								<h2 className={styles["details__text--heading"]}>{t("details:objectCollections")}</h2>
								<div>
									<ul className={styles["collection__list"]}>
										{predefinedCollectionsWithObject.map((item, key) => (
											<li className={styles["collection__item"]} key={item.id}>
												<div className={styles["collection__pic"]}>
													<div className={styles["collection__pic--img"]}>
														{item.jpg && (
															<img className={styles["collection__pic--img"]} alt={item.jpg} src={item.jpg} />
														)}
													</div>
												</div>
												<div className={styles["collection__content"]}>
													<div className={styles["collection__details"]}>
														<div className={styles["collection__title"]}>
															<Link to={url.collections.item.replace(":id", item.id)}>
																<h3 className={styles["collection__text--title"]}>
																	{ReactHtmlParser(getEmbeddedTitle(item, language))}
																</h3>
															</Link>
														</div>
														<div className={styles["collection__metadata"]}>
															<div className={styles["collection__metadata--item"]} key={key}>
																<span className={styles["collection__text--metadata"]}>
																	{t("home:collections.tile.date")}: <strong>{getEmbeddedDate(item, language)}</strong>
																</span>
															</div>
														</div>
														<div className={styles["collection__description"]}>
															<div className={styles["collection__text--description"]}>
																{ReactHtmlParser(getEmbeddedDescription(item, language))}
															</div>
														</div>
													</div>
													<div className={styles["collection__more"]}>
														<Button
															component={Link}
															variant="primary"
															to={url.collections.item.replace(":id", item.id)}
														>
															{t("details:seeCollection")}
														</Button>
													</div>
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						)}
					</div>
					{record.techType === recordDigital && (
						<div className={styles["details__sidebar"]}>
							{record.bsaPlaceOfPublishingRPT && (
								<div>
									<h2 className={styles["details__text--heading"]}>{t("details:geolocation")}</h2>
									<p className={styles["details__text--subheading"]}>{t("details:geolocationDetails")}</p>
									<div className={styles["details__map"]}>
										<Map
											center={record.bsaPlaceOfPublishingRPT[0].split(",").map(coord => parseFloat(coord))}
											zoom={13}
											scrollWheelZoom={true}
										>
											{/*                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                        />  */}
											<TileLayer
												url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
												attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
											/>
											<Marker
												icon={markerObject}
												position={record.bsaPlaceOfPublishingRPT[0].split(",").map(coord => parseFloat(coord))}
											>
												{record.bsaPlaceOfPublishing && (
													<Tooltip direction="top" offset={[0, -10]} opacity={1} permanent>
														{record.bsaPlaceOfPublishing}
													</Tooltip>
												)}
											</Marker>
										</Map>
									</div>
								</div>
							)}
							<div>
								<h2 className={styles["details__text--heading"]}>Moduły zawierające obiekt</h2>
								<div className={styles["details__modules"]}>
									<div className={`${styles["details__module"]} ${styles["details__module--bad"]}`}>
										<div className={styles["details__module--head"]}>
											<h3 className={styles["details__text--module-heading"]}>{t("modules.bad")}</h3>
										</div>
										<div className={styles["details__module--content"]}>
											{badObject && (
												<ul className={styles["details__module--list"]}>
													{badObject.map(item => {
														return (
															<li className={styles["details__module--item"]} key={item.id}>
																<h4 className={styles["details__text--module-title"]}>
																	<a href={`${url_BAD}/transcription/${item.id}`} target="_blank" rel="noreferrer">
																		{item.title}
																	</a>
																</h4>
																<p className={styles["details__text--module-content"]}>
																	{t("details:publicationAuthor")} {item.creator}
																</p>
																<p className={styles["details__text--module-content"]}>
																	{t("details:publicationDate")} {convertDate(item.dateCreated)}
																</p>
															</li>
														);
													})}
												</ul>
											)}
											<div>
												<Button
													variant="primary"
													href={`${url_BAD}/transcription/import?daceId=${record.id}`}
													target="_blank"
												>
													{t("details:createProject")}
												</Button>
											</div>
										</div>
									</div>
									<div className={`${styles["details__module"]} ${styles["details__module--agr"]}`}>
										<div className={styles["details__module--head"]}>
											<h3 className={styles["details__text--module-heading"]}>{t("modules.agr")}</h3>
										</div>
										<div className={styles["details__module--content"]}>
											{eduObject && (
												<ul className={styles["details__module--list"]}>
													{eduObject.map(item => {
														return (
															<li className={styles["details__module--item"]} key={item.id}>
																<h4 className={styles["details__text--module-title"]}>
																	<a href={item.url} target="_blank" rel="noreferrer">
																		{item.title}
																	</a>
																</h4>
																<p className={styles["details__text--module-content"]}>
																	{t("details:publicationAuthor")} {item.author}
																</p>
																<p className={styles["details__text--module-content"]}>
																	{t("details:publicationDate")} {convertDate(item.publishDate)}
																</p>
															</li>
														);
													})}
												</ul>
											)}
											<div>
												<Button href={url_EDU} target="_blank" rel="noreferrer" variant="primary">
													{t("details:goToModule")}
												</Button>
											</div>
										</div>
									</div>
									<div className={`${styles["details__module"]} ${styles["details__module--vol"]}`}>
										<div className={styles["details__module--head"]}>
											<h3 className={styles["details__text--module-heading"]}>{t("modules.vol")}</h3>
										</div>
										<div className={styles["details__module--content"]}>
											{wolObject && (
												<ul className={styles["details__module--list"]}>
													{wolObject.map(item => {
														return (
															<li className={styles["details__module--item"]} key={item.id}>
																<h4 className={styles["details__text--module-title"]}>
																	<a
																		href={`${url_WOL}/${language}/projects/${item.uri}`}
																		target="_blank"
																		rel="noreferrer"
																	>
																		{item.leadName}
																	</a>
																</h4>
																<p className={styles["details__text--module-content"]}>
																	{t("details:publicationAuthor")} {item.authorName}
																</p>
																<p className={styles["details__text--module-content"]}>
																	{t("details:publicationDate")} {convertDate(item.publishedDate)}
																</p>
															</li>
														);
													})}
												</ul>
											)}
											<div>
												<Button variant="primary" href={url_WOL} target="_blank" rel="noreferrer">
													{t("details:goToModule")}
												</Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Details;

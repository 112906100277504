import moment from "moment/moment";

/**
 * Create thumbnail url - resolution
 *
 * @param {string} id - id
 * @param {string} kind - kind
 * @function
 */

export function createThumbnailUrl(id, kind) {
	let postfix = "original";
	switch (kind) {
		case "smallest":
			postfix = "214x256";
			break;
		case "small":
			postfix = "228x288";
			break;
		case "normal":
			postfix = "474x600";
			break;
	}
	return `${process.env.REACT_APP_IMAGES}/thumbnails/${id}_${postfix}.jpg`;
}

/**
 * Format date by moment
 *
 * @param {string} timestamp - timestamp
 * @param {string} language - language
 * @function
 */

export function formatDate(timestamp, language) {
	// return moment(new Date(timestamp)).format(moment.localeData(language).longDateFormat("L"));
	return moment(new Date(timestamp)).format("DD.MM.YYYY");
}

export function format(formatted, ...params) {
	if (params) {
		for (let arg in params) {
			formatted = formatted.replace("{" + arg + "}", params[arg]);
		}
	}
	return formatted;
}

export const dataScope = ["dctermsCreated", "dctermsIssued", "bsaPrintDate"];

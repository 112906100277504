import React from "react";
import styles from "./Publications.module.sass";
import { Link } from "react-router-dom";
import url from "router/urls";
import imageNotFound from "@/assets/icons/svg/image-not-found.svg";

/**
* Publication item
* @memberof Components
* @function PublicationItem
*/

const PublicationItem = ({ item, t, index }) => {
	const hasValue = obj => obj && obj[0] && obj[0].toString().trim() !== "";

	return (
		<div className={styles["publications__item"]} key={item.id}>
			<div className={styles["publications__item--bottom"]}>
				<div className={styles["publications__item--bottom__title"]}>
					<h3 className={styles["publications__text--title"]}>
						<Link
							title={t("results:item.more")}
							to={{
								pathname: url.details.replace(":id", item.id),
								search: "from=" + url.recomended,
								state: { from: url.recomended, returnURL: url.home },
							}}
						>
							{item.dcTitle || item.original?.dcTitle || "Tytuł"}
						</Link>
					</h3>
				</div>
				<div className={styles["publications__item--bottom__info"]}>
					<div>
						{hasValue(item.dcCreator) && (
							<p className={styles["publications__text--info"]}>
								<span className={styles["publications__text--key"]}>{t("home:publications.tile.author")}: </span>
								<span className={styles["publications__text--value"]}>
									{item.dcCreator.length >= 2 ? item.dcCreator.join("; ") : item.dcCreator[0]}
								</span>
							</p>
						)}
						{hasValue(item.original?.dcCreator) && (
							<p className={styles["publications__text--info"]}>
							<span className={styles["publications__text--key"]}>{t("home:publications.tile.author")}: </span>
							<span className={styles["publications__text--value"]}>
								{item.original.dcCreator.length >= 2 ? item.original.dcCreator.join("; ") : item.original.dcCreator[0]}
							</span>
						</p>
						)}
					</div>
					<div>
						{hasValue(item.dcType) && (
							<p className={styles["publications__text--info"]}>
								<span className={styles["publications__text--key"]}>{t("home:publications.tile.type")}: </span>
								<span className={styles["publications__text--value"]}>{item.dcType}</span>
							</p>
						)}

						{hasValue(item.original?.dcType) && (
							<p className={styles["publications__text--info"]}>
								<span className={styles["publications__text--key"]}>{t("home:publications.tile.type")}: </span>
								<span className={styles["publications__text--value"]}>{item.original.dcType}</span>
							</p>
						)}
					</div>
					<div>
						
						{hasValue(item.dctermsIssued) && (
							<p className={styles["publications__text--info"]}>
								<span className={styles["publications__text--key"]}>{t("metadata:fields.dctermsIssued")}: </span>
								<span className={styles["publications__text--value"]}>{item.dctermsIssued}</span>
							</p>
						)}
						{hasValue(item.original?.dctermsIssued) && (
							<p className={styles["publications__text--info"]}>
								<span className={styles["publications__text--key"]}>{t("metadata:fields.dctermsIssued")}: </span>
								<span className={styles["publications__text--value"]}>{item.original.dctermsIssued}</span>
							</p>
						)}

						{(!hasValue(item.dctermsIssued) && hasValue(item.dctermsCreated)) && (
							<p className={styles["publications__text--info"]}>
								<span className={styles["publications__text--key"]}>{t("metadata:fields.dctermsCreated")}: </span>
								<span className={styles["publications__text--value"]}>{item.dctermsCreated}</span>
							</p>
						)}

						{(!hasValue(item.original?.dctermsIssued) && hasValue(item.original?.dctermsCreated)) && (
							<p className={styles["publications__text--info"]}>
								<span className={styles["publications__text--key"]}>{t("metadata:fields.dctermsCreated")}: </span>
								<span className={styles["publications__text--value"]}>{item.original.dctermsCreated}</span>
							</p>
						)}

						{(!hasValue(item.dctermsIssued) && !hasValue(item.dctermsCreated) && hasValue(item.bsaPrintDate)) && (
							<p className={styles["publications__text--info"]}>
								<span className={styles["publications__text--key"]}>{t("metadata:fields.bsaPrintDate")}: </span>
								<span className={styles["publications__text--value"]}>{item.bsaPrintDate}</span>
							</p>
						)}
						{(!hasValue(item.original?.dctermsIssued) && !hasValue(item.original?.dctermsCreated) && hasValue(item.original?.bsaPrintDate)) && (
							<p className={styles["publications__text--info"]}>
								<span className={styles["publications__text--key"]}>{t("metadata:fields.bsaPrintDate")}: </span>
								<span className={styles["publications__text--value"]}>{item.original.bsaPrintDate}</span>
							</p>
						)}
						
					</div>
				</div>
			</div>
			<a href={item.techUrl} title={t("details:openOnInstitutionPage")} target="_blank" data-noicon rel="noreferrer">
				<div className={styles["publications__item--tile"]}>
					<img
						src={process.env.REACT_APP_IMAGES + `/thumbnails/${item.id}_228x288.jpg`}
						alt={`Rekomendowana publikacja ${index}`}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null;
							currentTarget.src = imageNotFound;
						}}
					/>
				</div>
			</a>
		</div>
	);
};

export default PublicationItem;
